
import Vue from 'vue'
import { TIPS } from './tips';
import { Toast } from 'vant';

/* 页面加载 */
const Loading = {
  elem: null,
  show() {
    this.elem = Toast.loading({
      duration: 0,
      forbidClick: true,
      loadingType: 'spinner',
    });
  },
  close() {
    this.elem && this.elem.close()
  }
}

export default function () {

  // 请求拦截
  Vue.http.interceptors.push((req, next) => {
    let token = localStorage.getItem('CWX_TOKEN');
    if (token) {
      req.headers.set('token', `${token}`);
    }
    
    let config = req.method == 'GET' ? req : req.config;
    let { loading = false, tips = TIPS.default, showMessage = true } = config || {};
    //显示加载
    loading && Loading.show(tips);

    // 删除config
    req.body && (delete req.body.config);

    // continue to next interceptor
    next((response) => {
      Loading.close();
      let res = response.body;
      if (res.code != 200) {
        showMessage && Toast(res.msg || '请求失败');
      }
      return response;
    });
  });
}


