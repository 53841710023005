import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueResource from 'vue-resource';
import interceptors from '@/remote/interceptors';
import "amfe-flexible";
import Vant from 'vant';
import { Lazyload } from 'vant';
import '@vant/touch-emulator'; // 在桌面端自动将mouse事件转换成对应的touch事件

import 'vant/lib/index.css';
import 'vant/lib/picker/style/less';
import 'vant/lib/swipe/style/less';
import "./assets/less/base.less";

Vue.use(VueResource);
Vue.use(Vant);
Vue.use(Lazyload);


Vue.config.productionTip = false;

Vue.prototype.$EventBus = new Vue()

// 请求处理
interceptors();

// 路由拦截
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title || '睿鉴AI体验中心';
  }
  next();
})

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
