
export const TIPS = {
  //默认提示
  default: "加载中...",

  //登陆提示
  login: '正在登陆...',

  //超时
  timeout: '响应超时',
};