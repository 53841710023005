import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home/Index.vue'),
    meta: {
      title: '首页'
    }
  },
  {
    path: '/deep-detection',
    name: 'DeepDetection',
    component: () => import('../views/DeepDetection/Index.vue'),
    meta: {
      title: '深度合成智能检测'
    }
  },
  {
    path: '/contract-detection',
    name: 'ContractDetection',
    component: () => import('../views/ContractDetection/Index.vue'),
    meta: {
      title: 'PS合成检测'
    }
  },
  {
    path: '/deep-forge-trace',
    name: 'DeepForgeTrace',
    component: () => import('../views/DeepForgeTrace/Index.vue'),
    meta: {
      title: '深度伪造溯源'
    }
  },
  {
    path: '/trace-data',
    name: 'TraceData',
    component: () => import('../views/DeepForgeTrace/TraceData.vue'),
    meta: {
      title: '溯源数据展示'
    }
  },
  {
    path: '/trace-data-detail',
    name: 'TraceDataDetail',
    component: () => import('../views/DeepForgeTrace/TraceDataDetail.vue'),
    meta: {
      title: '溯源数据详情'
    }
  },
  {
    path: '/integrated-machine',
    name: 'IntegratedMachine',
    component: () => import('../views/IntegratedMachine/Index.vue'),
    meta: {
      title: '深伪检测一体机体验'
    }
  },
  {
    path: '/card-detection',
    name: 'CardDetection',
    component: () => import('../views/CardDetection/Index.vue'),
    meta: {
      title: 'OCR识别'
    }
  },
  {
    path: '/fake-face-solution',
    name: 'FakeFaceSolution',
    component: () => import('../views/FakeFaceSolution/Index.vue'),
    meta: {
      title: '身份认证伪造攻击及其解决方案'
    }
  },
  {
    path: '/video-attack',
    name: 'VideoAttack',
    component: () => import('../views/VideoAttack/Index.vue'),
    meta: {
      title: '音视频伪造及其解决方案'
    }
  },
  {
    path: '/fake-attack',
    name: 'FakeAndAttack',
    component: () => import('../views/FakeAndAttack/Index.vue'),
    meta: {
      title: '伪造攻击与防御'
    }
  },
  {
    path: '/check-info',
    name: 'CheckInfo',
    component: () => import('../views/VideoAttack/CheckInfo.vue'),
    meta: {
      title: '睿鉴检测'
    }
  },
  {
    path: '/text-detection',
    name: 'TextDetection',
    component: () => import('../views/TextDetection/Index.vue'),
    meta: {
      title: '多媒体违规内容检查'
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
